@import 'fonts';
@import 'colors';
@import 'inheritance';
@import 'mixin';
@import 'responsive';

.inner_bg {
  background: url(../img/bg/fundoGazeta.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.wlc_overlay {
  position: relative;
  z-index: 2;

  &:before {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background: $gradient43;
    z-index: -1;
    opacity: .6
  }
}